<template>
  <div class="ProductServe">
    <div class="top">
      <van-image :src="require('@/assets/img/ProductServe_01.png')" />
    </div>
    <div class="payroll_tax">
      <h3 class="title">{{ title1 }}</h3>
      <p class="notes">{{ viceTitle1 }}</p>
      <div class="payroll_tax_box">
        <div class="right">
          <div
            class="payroll_tax_box_rt"
            v-for="item in imgList4"
            :key="item.id"
            @click="jumpClick(item)"
          >
            <van-image :src="item.itemValue" fit="cover" />
            <!-- <h4 class="van-ellipsis">薪税外包服务</h4>
            <p>专家带团队/双语服务</p>
            <div>了解详情</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="finance_tax">
      <h3 class="title">{{ title2 }}</h3>
      <p class="notes">{{ viceTitle2 }}</p>
      <div class="finance_tax_List">
        <div
          :class="
            index % 2 == 0
              ? 'finance_tax_box left_rad'
              : 'finance_tax_box right_rad'
          "
          v-for="(item, index) in financeTaxList"
          :key="index"
          @click="jumpClick(item)"
        >
          <van-image :src="item.itemValue" fit="cover" />
          <!-- <h5>{{ item.title }}</h5>
          <p>{{ item.text }}</p>
          <p>{{ item.text2 }}</p> -->
        </div>

        <!-- <van-image :src="require('@/assets/img/ProductServe_05.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_06.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_07.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_08.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_09.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_10.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_11.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_12.jpg')" /> -->
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">{{ title3 }}</h3>
      <p class="notes">{{ viceTitle3 }}</p>
      <div class="banner">
        <van-swipe :autoplay="3000">
          <van-swipe-item
            v-for="image in images"
            :key="image.id"
            @click="jumpClick(image)"
          >
            <!-- <img v-lazy="image" /> -->
            <van-image
              width="100%"
              lazy-load
              fit="cover"
              :src="image.itemValue"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">{{ title4 }}</h3>
      <p class="notes">{{ viceTitle4 }}</p>
      <div class="banner">
        <van-swipe :autoplay="3000">
          <van-swipe-item
            v-for="image in images2"
            :key="image.id"
            @click="jumpClick(image)"
          >
            <!-- <img v-lazy="image" /> -->
            <van-image
              width="100%"
              lazy-load
              fit="cover"
              :src="image.itemValue"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage, Swipe, SwipeItem, Lazyload } from "vant";
import { addTPv } from "@/api/index";
import copyrightIp from "@/components/copyrightIp";
import { getTSpecificById } from "@/api/model";
import getJSSDK from "@/utils/wxShare";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Lazyload.name]: Lazyload,
    copyrightIp,
  },
  data() {
    return {
      title1: "",
      viceTitle1: "",
      imgList4: [],
      title2: "",
      viceTitle2: "",
      title3: "",
      viceTitle3: "",
      title4: "",
      viceTitle4: "",
      images: [
        // require("@/assets/img/ProductServe_13.png"),
        // require("@/assets/img/ProductServe_13.png"),
        // require("@/assets/img/ProductServe_13.png"),
        // require("@/assets/img/ProductServe_13.png"),
        // require("@/assets/img/ProductServe_13.png"),
        // require("@/assets/img/ProductServe_13.png"),
      ],
      images2: [
        // require("@/assets/img/ProductServe_13.png"),
        // require("@/assets/img/ProductServe_13.png"),
        // require("@/assets/img/ProductServe_13.png"),
      ],
      financeTaxList: [
        // {
        //   title: "财税全流程服务",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "https://zzxs.xinyanhecheng.com/dist/#/m3?id=11f92fde2d714d4b82",
        // },
        // {
        //   title: "常年税务服务",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "",
        // },
        // {
        //   title: "特殊税务服务",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "",
        // },
        // {
        //   title: "对外支付服务",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "https://zzxs.xinyanhecheng.com/dist/#/m3?id=4e8b021687f44ed781",
        // },
        // {
        //   title: "工会经费管理",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "https://zzxs.xinyanhecheng.com/dist/#/m3?id=4e8b021687f44ed781",
        // },
        // {
        //   title: "财务整理和审阅",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "https://zzxs.xinyanhecheng.com/dist/#/m3?id=1ad77509d6664ce0a6",
        // },
        // {
        //   title: "工会经费管理",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "/productServe/details",
        // },
        // {
        //   title: "对外支付服务",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "/productServe/details",
        // },
      ],
    };
  },
  mounted() {
    this.getTSpecific();
  },
  methods: {
    /*  onClick(data, index) {
      // this.$router.push("/productServe/details");
      window.location.href =
        "https://zzxs.xinyanhecheng.com/dist/#/m3?id=c9a500bdef224c9992";
    }, */
    jumpClick(data) {
      if (data.locationUrl) {
        window.location.href = data.locationUrl;
      }
      // let pvMenuName = title;
      // let pvOpenid = localStorage.getItem("code") || "";
      // let pvIp = localStorage.getItem("Ip") || "";
      // addTPv({ pvMenuName, pvOpenid, pvIp }).then((res) => {});
      // this.$router.push(url);
    },
    getTSpecific() {
      let tspecificId = window.location.hash.split("?")[1].split("=")[1];
      getTSpecificById({ tspecificId }).then((res) => {
        // this.imgUrl = res.data.data.tspecificTempItems[0]?.itemValue || "";
        console.log(res.data, "resresres");
        let data = res.data.data.tspecificTempItems;
        this.title1 = data[0].itemValue;
        this.viceTitle1 = data[1].itemValue;
        this.imgList4 = data.slice(2, 6);
        this.title2 = data[6].itemValue;
        this.viceTitle2 = data[7].itemValue;
        this.financeTaxList = data.slice(8, 14);
        this.title3 = data[14].itemValue;
        this.viceTitle3 = data[15].itemValue;
        this.images = data.slice(16, 22);
        this.title4 = data[22].itemValue;
        this.viceTitle4 = data[23].itemValue;
        this.images2 = data.slice(24, 27);
        getJSSDK({
          title: res.data.data.tspecific.specificName || "中智薪税",
          link: this.$route.fullPath,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ProductServe {
  background: #fff;
  .top {
    .van-image {
      width: 100%;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .notes {
    padding: 7px 0;
    font-size: 16px;
    color: #808492;
  }
  .payroll_tax {
    width: 100%;
    padding: 10px;
    .payroll_tax_box {
      width: 100%;
      height: 196px;
      display: flex;

      .payroll_tax_box_lf {
        width: 166px;
        min-width: 166px;
        height: 196px;
        padding: 5px;
        border-radius: 8px;
        background-image: url("../../assets/img/ProductServe_02.png");
        background-size: 100%;
        // background-repeat: no-repeat;
        h3 {
          font-size: 20px;
          font-weight: bold;
        }
        p {
          font-size: 12px;
          color: #999999;
          padding-bottom: 10px;
        }
        div {
          width: 75px;
          line-height: 22px;
          background: #ffefad;
          text-align: center;
          font-size: 12px;
          border-radius: 3px;
        }
      }
      .van-image {
        border-radius: 6px;
        overflow: hidden;
      }
      .left {
        width: 182px;
        min-width: 182px;
        padding-right: 10px;
      }
      .right {
        display: grid;
        grid-template-columns: repeat(2, 175px);
        grid-template-rows: repeat(2, 100px);
        grid-row-gap: 4px;
        grid-column-gap: 4px;

        .payroll_tax_box_rt {
          width: 100%;
          border-radius: 8px;
          // background-image: url("../../assets/img/ProductServe_03.png");
          background-size: 100%;
          // padding: 10px;
          background-repeat: no-repeat;
          overflow: hidden;
          .van-image {
            width: 100%;
            height: 100%;
          }
          &:nth-child(2) {
            // background-image: url("../../assets/img/ProductServe_04.png");
            div {
              background: #a0ddff;
            }
          }
          h4 {
            font-size: 16px;
          }
          p {
            font-size: 12px;
            color: #999999;
            padding: 6px 0;
          }

          // div {
          //   width: 75px;
          //   line-height: 22px;
          //   background: #fec19e;
          //   text-align: center;
          //   font-size: 12px;
          //   border-radius: 3px;
          // }
        }
      }
    }
  }
  .finance_tax {
    width: 100%;
    padding: 10px;
    .finance_tax_List {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 173px);
      grid-template-rows: repeat(3, 97px);
      grid-row-gap: 4px;
      grid-column-gap: 4px;

      .left_rad {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .right_rad {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .finance_tax_box {
        width: 100%;
        color: #fff;
        font-size: 12px;
        // padding: 6px;
        // padding-right: 50px;
        background-size: 100%;
        background-repeat: no-repeat;
        // position: relative;
        // border-radius: 3px;
        // border-bottom-left-radius: 10px;
        overflow: hidden;
        .van-image {
          width: 100%;
          height: 100%;
        }
        // h5 {
        //   font-size: 14px;
        //   width: 100%;
        //   padding-bottom: 5px;
        //   font-weight: bold;
        // }
        // &::after {
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   bottom: 0;
        //   left: 0;
        //   background-size: 100%;
        //   background-repeat: no-repeat;
        //   z-index: -1;
        // }
        // &:nth-child(1) {
        //   h5 {
        //     background: #ff6337;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_05.png");
        //   }
        // }
        // &:nth-child(2) {
        //   h5 {
        //     // background: #ee9722;
        //     background: transparent;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_06.png");
        //   }
        // }
        // &:nth-child(3) {
        //   h5 {
        //     // background: #88a5b1;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_07.png");
        //   }
        // }
        // &:nth-child(4) {
        //   h5 {
        //     // background: #ff6337;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_08.png");
        //   }
        // }
        // &:nth-child(5) {
        //   h5 {
        //     // background: #ff6337;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_09.png");
        //   }
        // }
        // &:nth-child(6) {
        //   h5 {
        //     // background: #ff6337;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_10.png");
        //   }
        // }
        // &:nth-child(7) {
        //   h5 {
        //     // background: #ff6337;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_11.png");
        //   }
        // }
        // &:nth-child(8) {
        //   h5 {
        //     // background: #ff6337;
        //   }
        //   &::after {
        //     background-image: url("../../assets/img/ProductServe_12.png");
        //   }
        // }
      }
    }
  }
  .bottom {
    width: 100%;
    padding: 10px;
    .banner {
      .van-image {
        width: 100%;
        height: 180px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
</style>
